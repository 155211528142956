document.addEventListener("DOMContentLoaded", function () {
    (() => {

        let elements = document.querySelectorAll('[data-bg-img]');
        elements.forEach((element) => {
            let image = element.dataset.bgImg;
            element.style.backgroundImage = "url('" + image + "')";
        });

    })();
});
